
import { Component, Prop, Mixins } from 'vue-property-decorator';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import DatePreview from '../ui/DatePreview.vue';
import { athleteProfile as athleteProfileStore } from '@/store';
import { TeamModel } from '../../models/team';
import { AthleteRoutingMixin, BAIconsMixin, MyAthleteMixin, StringsMixin, VuetifyMixin } from '@/mixins';

@Component({
	components: { DatePreview, SideNavSectionTitle },
})
export default class TeamNavItems extends Mixins(BAIconsMixin, VuetifyMixin, StringsMixin, MyAthleteMixin, AthleteRoutingMixin) {
	@Prop({ default: false }) mini: boolean;
	@Prop({ default: false }) ready: boolean;
	@Prop({ required: true }) teams: TeamModel[];

	expanded: boolean = false;

    get HasTeams(): boolean {
        return( this.IsNotEmptyArray(this.Teams) );
    }
    get Teams(): Array<TeamModel> {
        if( !athleteProfileStore.AthleteProfileIsReady ) return [];
        return athleteProfileStore.athleteProfileTeams;
    }
	get CurrentTeam(): TeamModel {
		if( !this.HasTeams ) return undefined;
		
		const team = this.teams.find(t => t.id === this.TheAthleteProfile.currentTeam);
		if( this.IsNotEmpty(team) ) return team;
		
		return this.teams[this.teams.length - 1];
	}
	get TeamsToShow(): Array<TeamModel> {
		if( !this.HasTeams ) return [];
		if( this.expanded ) return this.teams;
		return [this.CurrentTeam];
	}
}
